import { DWELLING_TABS, PAGE_TAB_MAP, TABS } from "../../constants";
import { TabName, TabStatus } from "../../types";
import {
  clearAllEventStreams,
  getKeyInMapByValue,
  getPageNameFromUrl,
  isDwelling,
  setFieldsEdited,
} from "../../utils";
import { getTabIcon, getTabIconStyles, getTabStatus } from "../../utilsMisc";
import { useDispatch, useSelector } from "../../redux/hooks";

import { ImgButton } from "../styled/buttons/misc";
import backButtonSvg from "../../assets/images/components/backButtonIcon.svg";
import { getPagenumber } from "../../utils/strings";
import { handleUpdateSession } from "../../services/utils";
import styled from "styled-components";
import { updateIsFetching } from "../../redux/actions/fetching";
import { updateIsFetchingQuotes } from "../../redux/actions/fetchingQuotes";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

type StyledProgressBarWrapperProps = {
  isDwelling: boolean;
};
export const StyledProgressBarWrapper = styled.div<StyledProgressBarWrapperProps>`
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: ${(props) => (props.isDwelling ? "30rem" : "50rem")};
`;

const BackButtonStyled = styled(ImgButton)`
  position: absolute;
  left: -35px;
  bottom: 10px;
  transform: translateX(-100%);
  padding: 0;
  & > img {
    width: 25px;
    height: 25px;
  }
`;

type BackButtonProps = {
  handleClick: () => void;
  pageName: string;
};
const BackButton = ({ handleClick, pageName }: BackButtonProps) => {
  const contact = useSelector((store) => store.contact);

  const { isReshop, isHomePolicySelected } = contact;

  const isBackButtonHidden =
    (isReshop && isHomePolicySelected && pageName === "PropertyInfo") ||
    pageName === "PolicyType";

  return isBackButtonHidden ? null : (
    <BackButtonStyled aria-label="Go Back" onClick={handleClick}>
      <img src={backButtonSvg} alt="back button" />
    </BackButtonStyled>
  );
};

const IconButton = styled.button`
  all: unset;
  grid-column: 1;
  box-sizing: content-box;
  border-radius: 50%;
  overflow: hidden;

  & svg {
    width: 41px;
    height: 41px;
  }

  &:enabled:hover {
    transform: translateY(-0.1rem);
  }
`;

type StyledIconContainerProps = {
  lineColor: string;
};
const StyledIconContainer = styled.li<StyledIconContainerProps>`
  display: grid;
  grid-template-columns: 41px 1fr;
  justify-items: center;
  align-items: center;
  font-size: 0.8rem;
  background-color: white;
  z-index: 1;

  &:not(:last-child) {
    flex-grow: 1;
    &::after {
      content: "";
      background: ${(props) => props.theme[props.lineColor]};
      height: 1.8px;
      width: 100%;
    }
  }
`;

type StyledTabNameProps = {
  status: string;
};
const StyledTabName = styled.div<StyledTabNameProps>`
  grid-column: 1;
  color: ${(props) =>
    props.status === "disabled" ? "gray" : props.theme.green3};
`;

const StyledProgressBarUl = styled.ul`
  all: unset;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

type ProgressBarLiProps = {
  tab: TabName;
  status: TabStatus;
  nextTabStatus: TabStatus;
  lob: string;
};
const ProgressBarLi = ({
  tab,
  status,
  nextTabStatus,
  lob,
}: ProgressBarLiProps) => {
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Icon = getTabIcon(tab);
  const styles = getTabIconStyles(status);

  // tab cannot be Details because Auto Details uses this key
  const tabName = tab === "Home Details" ? "Details" : tab;

  const handleClick = async () => {
    const { quoteEvents } = window as unknown as Window & {
      quoteEvents: any[];
    };

    if (tab === "Policy") {
      setFieldsEdited();
    }
    await handleUpdateSession({
      uuid: session.uuid,
      line_of_business: lob,
      page_stopped: getPagenumber(lob, getKeyInMapByValue(PAGE_TAB_MAP, tab)),
    });
    clearAllEventStreams(quoteEvents);
    if (tab === "Results") {
      dispatch(updateIsFetching(true));
    }
    dispatch(updateIsFetchingQuotes(false));

    navigate(`/agent/${lob}/${getKeyInMapByValue(PAGE_TAB_MAP, tab)}`);
  };

  const isDisabled = status === "disabled";
  const isNextTabDisabled = nextTabStatus === "disabled";
  const lineColor = isDisabled || isNextTabDisabled ? "disabled" : "green3";

  return (
    <StyledIconContainer lineColor={lineColor}>
      <StyledTabName status={status}>{tabName}</StyledTabName>
      <IconButton
        disabled={isDisabled}
        aria-label={`go to tab ${tab}`}
        onClick={handleClick}
      >
        <Icon style={styles} />
      </IconButton>
    </StyledIconContainer>
  );
};

type ProgressBarProps = {
  handleBack: () => void;
  lob: string;
};
export const ProgressBar = ({ handleBack, lob }: ProgressBarProps) => {
  const pageName = getPageNameFromUrl();
  const currentTab = PAGE_TAB_MAP.get(pageName) as TabName;
  const tabs = (isDwelling() ? DWELLING_TABS : TABS) as TabName[];
  const currentIndex = useMemo(
    () => tabs.findIndex((tab) => tab === currentTab),
    [tabs, currentTab]
  );

  return (
    <StyledProgressBarWrapper isDwelling={isDwelling()}>
      <StyledProgressBarUl>
        <BackButton handleClick={handleBack} pageName={pageName} />
        {tabs.map((tab, index) => {
          const status = getTabStatus({ tab, index, currentIndex });
          const nextTab = tabs[index + 1];
          const nextTabStatus = getTabStatus({
            tab: nextTab,
            index: index + 1,
            currentIndex,
          });

          return (
            <ProgressBarLi
              key={tab}
              tab={tab}
              status={status}
              nextTabStatus={nextTabStatus}
              lob={lob}
            />
          );
        })}
      </StyledProgressBarUl>
    </StyledProgressBarWrapper>
  );
};
