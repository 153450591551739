import {
  CarrierLogoName,
  CheckoutInfo,
  QuoteCard,
  QuoteCardList,
  QuoteDetails,
} from "./Quote";
import type { Quote, QuoteBundle } from "../../types";
import { StyledBundleHeaderContainer, StyledQuoteLobContainer } from "./styled";
import {
  formatNumberToDollars,
  getAutoPremiumPerYear,
  getHomeFloodPremiumPerYear,
  getLobIcon,
  getQuoteCardNameInfo,
  padNumbersWithZero,
} from "../../utilsMisc";

import { ReactComponent as Auto } from "../../assets/images/main/lob/auto-filled.svg";
import { CopilotAlert } from "./alerts/CopilotAlert";
import { ReactComponent as Packages } from "../../assets/images/components/response-page/Packages.svg";
import { QuoteSpouseButton } from "./QuoteSpouseButton";
import { getHomeQuotesTitle } from "../../utils/quotes";

type BundleHeaderProps = { numItems: number };
const BundleHeader = ({ numItems }: BundleHeaderProps) => {
  const HomeIcon = getLobIcon("home");
  return (
    <StyledBundleHeaderContainer>
      <div className="packages">
        <Packages />
        <span>Packages</span>
      </div>
      <div className="lobs">
        <span className="lob-icon-text">
          {!!HomeIcon && <HomeIcon />}
          <h2>{getHomeQuotesTitle()}</h2>
        </span>
        <span className="lob-icon-text">
          <Auto />
          <h2>Auto</h2>
        </span>
      </div>
      <span className="num-response">{`(${padNumbersWithZero(
        numItems
      )})`}</span>
    </StyledBundleHeaderContainer>
  );
};

type BundleCollapsableProps = {
  homeQuote: Quote;
  autoQuote: Quote;
};
const BundleCollapsable = ({
  homeQuote,
  autoQuote,
}: BundleCollapsableProps) => {
  const homeQuoteInfo = getQuoteCardNameInfo(homeQuote, "home");
  const autoQuoteInfo = getQuoteCardNameInfo(autoQuote, "auto");

  return (
    <div className="bundle-dropdown-container">
      <QuoteDetails
        quote={homeQuote}
        firstName={homeQuoteInfo.firstName}
        hasNameInDropdown={homeQuoteInfo.hasNameInDropdown}
      />
      <QuoteDetails
        quote={autoQuote}
        firstName={autoQuoteInfo.firstName}
        hasNameInDropdown={autoQuoteInfo.hasNameInDropdown}
      />
    </div>
  );
};

type BundleQuoteCardProps = { quoteBundle: QuoteBundle };
const BundleQuoteCard = ({ quoteBundle }: BundleQuoteCardProps) => {
  const { homeQuote, autoQuote } = quoteBundle;
  const homePremiumPerYear = getHomeFloodPremiumPerYear(homeQuote);
  const autoPremiumPerYear = getAutoPremiumPerYear(autoQuote);
  const totatlPremiumPerYear =
    homePremiumPerYear !== null && autoPremiumPerYear !== null
      ? homePremiumPerYear + autoPremiumPerYear
      : null;

  return (
    <QuoteCard
      isBundle
      collapsable={
        <BundleCollapsable homeQuote={homeQuote} autoQuote={autoQuote} />
      }
    >
      <div className="total-premium-info">
        {totatlPremiumPerYear !== null && (
          <>
            {formatNumberToDollars(totatlPremiumPerYear)}/yr
            <span className="below-info">Package Total</span>
          </>
        )}
      </div>
      <div className="quote-bundle">
        <div className="quote-card-info">
          <div className="quote-card-left">
            <CarrierLogoName
              carrierId={homeQuote.company_client_id__c}
              carrierName={homeQuote.carrier__c}
            />
            <QuoteSpouseButton quote={homeQuote} />
          </div>
          <div className="right-section">
            <CopilotAlert quote={homeQuote} />
            <CheckoutInfo quote={homeQuote} lob="home" />
          </div>
        </div>
        <div className="quote-card-info">
          <div className="quote-card-left">
            <CarrierLogoName
              carrierId={autoQuote.company_client_id__c}
              carrierName={autoQuote.carrier__c}
            />
            <QuoteSpouseButton quote={autoQuote} />
          </div>
          <div className="right-section">
            <CopilotAlert quote={autoQuote} />
            <CheckoutInfo quote={autoQuote} lob="auto" />
          </div>
        </div>
      </div>
    </QuoteCard>
  );
};

type BundleQuoteListContainerProps = {
  quoteBundles: QuoteBundle[];
};
export const BundleQuoteListContainer = ({
  quoteBundles,
}: BundleQuoteListContainerProps) => {
  return (
    <StyledQuoteLobContainer className="quote-list-container">
      <BundleHeader numItems={quoteBundles.length} />
      <QuoteCardList>
        {quoteBundles.map((quoteBundle, index) => (
          <BundleQuoteCard key={index} quoteBundle={quoteBundle} />
        ))}
      </QuoteCardList>
    </StyledQuoteLobContainer>
  );
};
