import type {
  ManualQuoteResponse,
  QuoteBundle,
  Quote as QuoteResponse,
} from "../types";
import { useEffect, useMemo, useState } from "react";
import {
  VIVINT_SMART_HOME_QUOTE,
  auto,
  bundle,
  flood,
  home,
  jewelry,
  life,
  lifePrimary,
  lifeSpouse,
  security,
  umbrella,
} from "../constants";

import { filterQuotes, validateStateToDisplay } from "../utils/quotes";

import { getMonolineQuotes, mapManualQuote } from "../utilsMisc";
import { isDwelling } from "../utils";

import { BundleQuoteListContainer } from "./new_design/BundleQuoteListContainer";
import { MonolineQuoteListContainer } from "./new_design/MonolineQuoteListContainer";
import { StyledRatesContainer } from "./new_design/styled";
import { getLineOfBusiness } from "../utils/strings";
import { getNonRaterCarriers } from "../services/user";
import stateConfigsJson from "../constants.state-configs.json";
import styled from "styled-components";
import { useFeatureFlag } from "../services/featureFlag";
import { useSelector } from "../redux/hooks";

export const BundleTotalText = styled.div`
  color: #2d424d;
  font-size: 24px;
  font-weight: bold;
  font-family: ${(props) => props.theme.font_header_base};
`;

interface CarrierConfig {
  carrierId: number;
  carrierName: string;
  isMonolineAcceptable: boolean;
  isMonolineIneligibleInCertainCounties: boolean;
  monolineIneligibleCounties: null | string[];
  monolineNotes?: string;
  packageDiscountType: string;
}

interface StateConfig {
  auto: { [key: string]: CarrierConfig };
  home: { [key: string]: CarrierConfig };
}

interface StateConfigs {
  [state: string]: StateConfig;
}

const Rates = ({
  setShowSmartHomePackageSelectionModal,
}: {
  setShowSmartHomePackageSelectionModal: () => void;
}) => {
  const featureFlags = useFeatureFlag();
  const contact = useSelector((store) => store.contact);
  const session = useSelector((store) => store.session);
  const property = useSelector((store) => store.property);
  const quote = useSelector((store) => store.quote);
  const quotes = quote?.quotes;
  const isDwellingQuote = isDwelling();

  const vivintSmartHomeReferralFlag = featureFlags.getFlag(
    "vivintSmartHomeReferral"
  );
  const { __meta, ...rest } = stateConfigsJson;
  const stateConfigs: StateConfigs = rest as StateConfigs;

  const showVivintSmartHomeQuote =
    contact.homeQuoteId && vivintSmartHomeReferralFlag;

  const isMultiLine = (session.line_of_business?.indexOf("Auto") ?? 0) > 0;

  const isDwellingOnly = !isMultiLine && isDwelling();

  const filteredHomeCarriers = contact?.filteredHomeCarriers;
  const filteredAutoCarriers = contact?.filteredAutoCarriers;

  const [nonRaterCarriers, setNonRaterCarriers] = useState<{
    home: ManualQuoteResponse[];
    auto: ManualQuoteResponse[];
  }>({
    home: [],
    auto: [],
  });

  const { filteredBundledQuotes, filteredHomeQuotes, filteredAutoQuotes } =
    filterQuotes(quotes, filteredHomeCarriers, filteredAutoCarriers);

  // TODO: Change type from any to actual types
  const filteredQuotes: Record<string, any[] | undefined> = useMemo(
    () => ({
      home: validateStateToDisplay(filteredHomeQuotes) ?? [],
      auto: validateStateToDisplay(filteredAutoQuotes) ?? [],
      bundle: isDwellingQuote ? [] : filteredBundledQuotes ?? [],
      life: [...(quotes?.[lifePrimary] ?? []), ...(quotes?.[lifeSpouse] ?? [])],
      flood: quotes?.[flood] ?? [],
      jewelry: quotes?.[jewelry] ?? [],
      umbrella: quotes?.[umbrella] ?? [],
      lifePrimary: quotes?.[lifePrimary],
      lifeSpouse: quotes?.[lifeSpouse],
      ...(contact.homeQuoteId ? { security: [VIVINT_SMART_HOME_QUOTE] } : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredAutoQuotes, filteredBundledQuotes, filteredHomeQuotes, quotes]
  );

  useEffect(() => {
    const manualQuoteCarriers = async () => {
      try {
        if (
          (contact.user || session.agent_sfid) &&
          property.administrative_area_level_1
        ) {
          const lob = getLineOfBusiness(session.line_of_business || "");
          let homeNonRaterCarriers: ManualQuoteResponse[] = [];
          let autoNonRaterCarriers: ManualQuoteResponse[] = [];
          if (lob === "HomeAuto" || lob === "Home") {
            homeNonRaterCarriers = (
              await getNonRaterCarriers(
                "home",
                property.administrative_area_level_1,
                (contact.user || session.agent_sfid)!
              )
            ).data;
          }
          if (lob === "HomeAuto" || lob === "Auto") {
            autoNonRaterCarriers = (
              await getNonRaterCarriers(
                "auto",
                property.administrative_area_level_1,
                (contact.user || session.agent_sfid)!
              )
            ).data;
          }
          setNonRaterCarriers({
            home: homeNonRaterCarriers,
            auto: autoNonRaterCarriers,
          });
        }
      } catch (e) {
        setNonRaterCarriers({
          home: [],
          auto: [],
        });
      }
    };

    manualQuoteCarriers();
    // eslint-disable-next-line
  }, [contact.accountId, property.administrative_area_level_1]);

  // TODO: remove this after old design is removed
  const homeRaterCarrierQuotes = filteredQuotes["home"] ?? [];
  const autoRaterCarrierQuotes = filteredQuotes["auto"] ?? [];

  const state = property?.administrative_area_level_1 ?? "";
  const county =
    property?.administrative_area_level_2?.replace(" County", "") ?? "";

  const monolineHomeQuotes = getMonolineQuotes(
    homeRaterCarrierQuotes,
    "home",
    isMultiLine,
    stateConfigs,
    state,
    county
  );

  const monolineAutoQuotes = getMonolineQuotes(
    autoRaterCarrierQuotes,
    "auto",
    isMultiLine,
    stateConfigs,
    state,
    county
  );

  const nonRaterCarrierHomeQuotes = (nonRaterCarriers["home"] ?? [])
    .map((manualQuote) => mapManualQuote(manualQuote, "home"))
    .sort((a, b) => a.carrier__c.localeCompare(b.carrier__c)); // sort alphabetically

  const nonRaterCarrierAutoQuotes = (nonRaterCarriers["auto"] ?? [])
    .map((manualQuote) => mapManualQuote(manualQuote, "auto"))
    .sort((a, b) => a.carrier__c.localeCompare(b.carrier__c)); // sort alphabetically

  const hasHomeOrAutoQuotes =
    quotes?.home?.length !== 0 || quotes?.auto?.length !== 0;

  return (
    <>
      {hasHomeOrAutoQuotes && (
        <StyledRatesContainer isDwellingOnly={isDwellingOnly}>
          <div className="width-container">
            {contact.activeTab === "package" && (
              // TODO: remove this casting by assigning correct types
              <section className="bundle-section">
                <BundleQuoteListContainer
                  quoteBundles={filteredQuotes[bundle] as QuoteBundle[]}
                />
              </section>
            )}
            {contact.activeTab === "split" && (
              <>
                <section className="monoline-section">
                  <MonolineQuoteListContainer
                    lob={home}
                    quotesByType={{
                      Monoline: monolineHomeQuotes,
                      "Quote Manually": nonRaterCarrierHomeQuotes,
                    }}
                    key={home}
                  />
                </section>
                {!isDwellingOnly && (
                  <section className="monoline-section">
                    <MonolineQuoteListContainer
                      lob={auto}
                      quotesByType={{
                        Monoline: monolineAutoQuotes,
                        "Quote Manually": nonRaterCarrierAutoQuotes,
                      }}
                      key={auto}
                    />
                  </section>
                )}
              </>
            )}

            <section className="additional-section">
              <MonolineQuoteListContainer
                lob="jewelry"
                quotesByType={{
                  Monoline: (filteredQuotes[jewelry] as QuoteResponse[]).filter(
                    ({ package_rate__c }) => !package_rate__c
                  ),
                  // TODO: remove this filter after old design is removed and no duplicates exist
                  "Quote Manually": [],
                }}
              />
              <MonolineQuoteListContainer
                lob={umbrella}
                quotesByType={{
                  Monoline: (
                    filteredQuotes[umbrella] as QuoteResponse[]
                  ).filter(({ package_rate__c }) => !package_rate__c),
                  // TODO: remove this filter after old design is removed and no duplicates exist
                  "Quote Manually": [],
                }}
              />
              <MonolineQuoteListContainer
                lob="flood"
                quotesByType={{
                  Monoline: (filteredQuotes[flood] as QuoteResponse[]).filter(
                    ({ package_rate__c }) => !package_rate__c
                  ),
                  // TODO: remove this filter after old design is removed and no duplicates exist
                  "Quote Manually": [],
                }}
              />
              <MonolineQuoteListContainer
                lob="life"
                quotesByType={{
                  Monoline: (filteredQuotes[life] as QuoteResponse[]).filter(
                    ({ package_rate__c }) => !package_rate__c
                  ),
                  // TODO: remove this filter after old design is removed and no duplicates exist
                  "Quote Manually": [],
                }}
              />
              {showVivintSmartHomeQuote && (
                <MonolineQuoteListContainer
                  lob="security"
                  quotesByType={{
                    Monoline: filteredQuotes[security] as QuoteResponse[],
                    "Quote Manually": [],
                  }}
                />
              )}
            </section>
            {isDwellingOnly && <section className="monoline-section"></section>}
          </div>
        </StyledRatesContainer>
      )}
    </>
  );
};

export default Rates;
