import { ADD_CONTACT, CLEAR_CONTACT } from "../constants";

import { ssnPattern } from "../../constants";
import { nameify } from "../../utils/nameify/nameify";

export const addContact = (contact) => {
  const primaryFormatterName = nameify(contact.firstName, contact.lastName);

  const spouseFormatterName = nameify(
    contact.spousefirstName,
    contact.spouselastName
  );

  return {
    type: ADD_CONTACT,
    payload: {
      ...contact,
      ...(primaryFormatterName
        ? {
            firstName: primaryFormatterName.firstName,
            lastName: primaryFormatterName.lastName,
          }
        : {}),
      ...(contact.ssn && ssnPattern.test(contact.ssn)
        ? {
            ssn: contact.ssn,
          }
        : {}),
      ...(contact.spouseSsn && ssnPattern.test(contact.spouseSsn)
        ? {
            spouseSsn: contact.spouseSsn,
          }
        : {}),
      ...(spouseFormatterName
        ? {
            spousefirstName: spouseFormatterName.firstName,
            spouselastName: spouseFormatterName.lastName,
          }
        : {}),
    },
  };
};

export const clearContact = () => {
  return {
    type: CLEAR_CONTACT,
  };
};
