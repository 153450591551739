type NameifyOutput = {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  initials?: string;
};

const prefixes = ["Mc"];

/**
 * Capitalizes the first letter of a word
 *
 * @example
 *   capitalizeWord("jOHn") => "John"
 *   capitalizeWord("DOE") => "Doe"
 *   capitalizeWord("smith") => "Smith"
 *
 * @param word String
 * @returns Capitalized word
 */
function capitalizeWord(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

/**
 * Formats a name string
 *
 * @example
 *   formatName("JOHN") => "John"
 *   formatName("aLIce") => "Alice"
 *   formatName("MARY-JANE") => "Mary-Jane"
 *   formatName("O'neill") => "O'Neill"
 *
 * @param name String
 * @returns Formatted name
 */
function formatName(name: string): string {
  // Split the name into parts using hyphens, spaces, and apostrophes as delimiters
  const nameParts: string[] = name.split(/(?=[\s-'])|(?<=[\s-'])/);

  return nameParts
    .map((part) => {
      if (part.match(/[\s-']/)) {
        // If part is a separator (' ', '-', or "'"), return it as it is
        return part;
      }

      // Check if the part starts with any of the known prefixes
      const prefix = prefixes.find((prefix) =>
        part.toLowerCase().startsWith(prefix.toLowerCase())
      );

      //Special case for parts that are Lee or any other prefix that only 3 characters long
      if (prefix && part.length > 3) {
        // Ensure only the first character after the prefix is capitalized
        return prefix + capitalizeWord(part.slice(prefix.length));
      }

      // Capitalize the word
      return capitalizeWord(part);
    })
    .join("");
}

/**
 * Get the initials of a name
 *
 * @example
 *   getInitials("John Doe") => "JD"
 *   getInitials("Anna Maria") => "AM"
 *
 * @param name String
 * @returns The initials of the name
 */
function getInItials(firstName: string, lastName: string): string {
  const firstInitial: string = firstName.charAt(0).toUpperCase();
  const lastInitial: string = lastName.charAt(0).toUpperCase();

  return `${firstInitial}${lastInitial}`;
}

/**
 * Formats a first and last name into a single string
 *
 * @example
 *   nameify("JOE-BOB", "Ellis") => "Joe-Bob Ellis"
 *   nameify("Joe Bobby-brown", "Mcdonnald") => "Joe Bobby-Brown Mcdonnald"
 *   nameify("ANNA", "smith") => "Anna Smith"
 *   nameify("o'neill", "doe") => "O'Neill Doe"
 *
 * @param firstName String
 * @param lastName String
 * @returns NameifyOutput
 */
export function nameify(
  firstName: string | undefined,
  lastName: string | undefined
): NameifyOutput | null {
  if (!firstName || !lastName) {
    return null;
  }

  const formattedFirstName: string = formatName(firstName);
  const formattedLastName: string = formatName(lastName);
  const fullName: string = `${formattedFirstName} ${formattedLastName}`;
  const initials: string = getInItials(formattedFirstName, formattedLastName);

  return {
    firstName: formattedFirstName,
    lastName: formattedLastName,
    fullName,
    initials,
  };
}

export { capitalizeWord, formatName, getInItials };
