import { ADD_DRIVERS } from "../constants";

import { nameify } from "../../utils/nameify/nameify";

export const addDrivers = (drivers) => {
  const data = drivers.map((driver) => {
    const formatterName = nameify(driver.firstName, driver.lastName);
    return {
      ...driver,
      ...(formatterName
        ? {
            firstName: formatterName.firstName,
            lastName: formatterName.lastName,
          }
        : {}),
    };
  });

  return {
    type: ADD_DRIVERS,
    payload: data,
  };
};
