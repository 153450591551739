import { FLOOD_ZONE_CARRIER_ORDER, excludedBranchCounties } from "./constants";

import moment from "moment";
import { stateSpecificBodilyInjuryOptions } from "../constants";

export const getStateSpecificBodilyInjuryOptions = (state) =>
  stateSpecificBodilyInjuryOptions[state];

export const calculateDwellingCoverage = (sqFt, dollarsPerSqFt) => {
  if (sqFt >= 4000 && sqFt <= 4500) {
    return Math.floor(sqFt * dollarsPerSqFt * 1.09);
  }
  if (sqFt > 4500 && sqFt <= 5000) {
    return Math.floor(sqFt * dollarsPerSqFt * 1.14);
  }
  if (sqFt > 5000 && sqFt <= 5500) {
    return Math.floor(sqFt * dollarsPerSqFt * 1.26);
  }
  if (sqFt > 5500 && sqFt <= 6000) {
    return Math.floor(sqFt * dollarsPerSqFt * 1.32);
  }
  if (sqFt > 6000 && sqFt <= 6500) {
    return Math.floor(sqFt * dollarsPerSqFt * 1.38);
  }
  if (sqFt > 6500) {
    return Math.floor(sqFt * dollarsPerSqFt * 1.75);
  }
  return Math.floor(sqFt * dollarsPerSqFt);
};

export const skipButtonTitle = (lob) => {
  return lob.slice(0, -4) === "Renter"
    ? lob.slice(0, -4) + "s"
    : lob.slice(0, -4);
};

/**
 * @param {any} contact - Contact object
 * @param {any} property - Property object
 * @returns {boolean} - It will return a boolean
 *
 *   - This function will return true or false based on the input.
 */
export const isValidCounty = (contact, property) => {
  if (contact.autoQuoteId) return true;
  const countySplit = (property.administrative_area_level_2 || "").split(" ");
  if (countySplit.length) {
    if (countySplit[countySplit.length - 1].toLowerCase() === "county")
      countySplit.pop();
    const county = countySplit.join(" ");
    if (excludedBranchCounties.includes(county.toLowerCase())) {
      return false;
    }
  }
  return true;
};

export const isOlderThan30 = (yearBuilt) => moment().year() - yearBuilt > 30;

export const getFloodZone = (quoteResponses) => {
  const sortedQuoteRseponses = [...quoteResponses].sort(
    (a, z) =>
      FLOOD_ZONE_CARRIER_ORDER.indexOf(a.carrier__c) -
      FLOOD_ZONE_CARRIER_ORDER.indexOf(z.carrier__c)
  );
  const floodZone = sortedQuoteRseponses.find(
    ({ carrier__c, line_of_business__c, flood_zone__c }) =>
      FLOOD_ZONE_CARRIER_ORDER.includes(carrier__c) &&
      line_of_business__c === "Flood" &&
      !!flood_zone__c
  )?.flood_zone__c;

  return floodZone;
};

export const calculateEffectiveDate = (expirationDate, effectiveDate) => {
  const currentDate = moment();
  const maxFutureDate = moment().add(60, "days");

  const isInFuture = (date) => currentDate.isBefore(date, "day");
  const isTooFuture = (date) => maxFutureDate.isBefore(date, "day");
  const isWithinMaxFuture = (date) =>
    currentDate.isBefore(date, "day") && maxFutureDate.isAfter(date, "day");
  const checkDate = (date) => (isTooFuture(date) ? null : date);

  if (!isWithinMaxFuture(expirationDate) && !isWithinMaxFuture(effectiveDate)) {
    return null;
  }

  if (isInFuture(effectiveDate)) {
    return checkDate(effectiveDate);
  }

  return checkDate(expirationDate);
};

export const propertyValueExistsInObject = (array, propertyName, value) => {
  return array.some((obj) => obj[propertyName].includes(value));
};

export const handleIntInputKeyDown = (event) => {
  // Prevent "e", "+", "-", "." in number inputs
  if (["e", "E", "+", "-", "."].includes(event.key)) {
    event.preventDefault();
  }
};
