import { MouseEventHandler, useMemo } from "react";

import { ReactComponent as CondoIcon } from "../../assets/images/main/lob/condo-outline.svg";
import { ReactComponent as HomeIcon } from "../../assets/images/main/lob/home-outline.svg";
import { ReactComponent as RentersIcon } from "../../assets/images/main/lob/renters-filled.svg";
import { StyledSkipButton } from "./styled";
import { capitalize } from "lodash";
import { isDwelling } from "../../utils";
import { skipButtonTitle } from "../../utils/misc";
import { useSelector } from "../../redux/hooks";

const homeLobIconMap = new Map([
  ["Home", HomeIcon],
  ["Condo", CondoIcon],
  ["Renters", RentersIcon],
]);

type SkipButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};
export const SkipButton = ({ onClick }: SkipButtonProps) => {
  const session = useSelector((store) => store.session);
  const sessionLob = session?.line_of_business;
  const lobText = useMemo(
    () => (isDwelling() ? "Dwelling" : capitalize(skipButtonTitle(sessionLob))),
    [sessionLob]
  );
  const Icon = useMemo(() => homeLobIconMap.get(lobText), [lobText]);
  if (!Icon) {
    return null;
  }
  return (
    <StyledSkipButton onClick={onClick}>
      <Icon />
      <span>{`Submit ${lobText} Only`}</span>
    </StyledSkipButton>
  );
};
