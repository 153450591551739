import { HISTORY, SUMMARY, auto, home } from "../constants";
import {
  Incident,
  PreviousSession,
  PriorInsuranceInfo,
} from "../redux/reducers/types";
import {
  fetchAutoDetails,
  filterIncident,
  mapIncidentResponseToForm,
} from "../utils";
import {
  getAddress,
  getLobIcon,
  getPropertyAddressFromSessionPropertyData,
  isMultiLine,
  trimStringToMaxLength,
  useDropdown,
} from "../utilsMisc";
import { useEffect, useMemo, useState } from "react";

import { ReactComponent as Auto } from "../assets/images/main/lob/auto-filled.svg";
import { ReactComponent as Condo } from "../assets/images/main/lob/condo-filled.svg";
import { ReactComponent as DoubleArrowRight } from "../assets/images/components/toolbar/DoubleArrowRight.svg";
import { ReactComponent as Dwelling } from "../assets/images/main/lob/dwelling-filled.svg";
import { ReactComponent as History } from "../assets/images/components/toolbar/History.svg";
import { ReactComponent as Home } from "../assets/images/main/lob/home-filled.svg";
import { ReactComponent as Jewelry } from "../assets/images/main/lob/jewelry-filled.svg";
import { ReactComponent as Location } from "../assets/images/components/response-page/Location.svg";
import { ReactComponent as PersonSecondary } from "../assets/images/components/toolbar/PersonSecondary.svg";
import { ReactComponent as Renters } from "../assets/images/main/lob/renters-filled.svg";
import { ReactComponent as Summary } from "../assets/images/components/toolbar/Summary.svg";
import { convertToDollars } from "../utils/strings";
import { getAccidentsViolations } from "../services/accidents";
import { getHomeQuotesTitle } from "../utils/quotes";
import { getSessionsBySfAccountId } from "../services/session";
import moment from "moment";
import styled from "styled-components";
import { useSelector } from "../redux/hooks";

const getHomeIcon = ({ line_of_business, policy_form__c }: PreviousSession) => {
  const hasHomeOrDwellingQuote = line_of_business?.includes("Home");
  const hasRentersQuote = line_of_business?.includes("Renter");
  const hasCondoQuote = line_of_business?.includes("Condo");

  if (hasHomeOrDwellingQuote) {
    return policy_form__c?.toLowerCase() === "dp3" ? Dwelling : Home;
  }
  if (hasRentersQuote) {
    return Renters;
  }
  if (hasCondoQuote) {
    return Condo;
  }
  return Home;
};

const StyledQuoteTools = styled.div`
  & .quote-tools {
    display: flex;
    position: absolute;
    right: 5px;
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    z-index: 999;
    & button {
      width: 108px;
      height: 32px;
      flex-grow: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      align-self: center;
      :hover {
        background-color: #f2f1ef;
      }
    }
    & button.active {
      background-color: #f2f1ef;
    }
  }

  & .quote-tools-content {
    width: 450px;
    height: calc(100vh - 60px - 32px);
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 15px;
    border: solid 1px #d8d7d4;
    border-top: none;
    background-color: #fff;
    position: absolute;
    right: 0;
    z-index: 99;
  }

  & .double-arrow {
    position: absolute;
    transform: translateX(-210px);
    width: auto !important;
  }

  & .summary-info {
    margin: 20px;
  }
  & .history-info {
    margin: 5px;
  }
  & .summary-info,
  & .history-info {
    margin-top: 50px;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  & .lob-content {
    height: 50svh;
    overflow-y: auto;
    & svg {
      color: #157f3a;
    }
  }

  & .quote-info {
    display: flex;
    margin-left: 40px;
    padding-right: 20px;
    justify-content: space-between;
    & .quote-info-section1 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
    & .quote-info-section2 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-end;
    }
    & .vehicle {
      gap: 0px;
    }
  }
  & .quote-info-content {
    font-size: 12px;
    font-weight: normal;
    color: #626262;
  }

  & .contact-name {
    font-size: 14px;
    font-weight: 500;
    margin-left: 40px;
    margin-bottom: 10px;
  }

  & .person-logo,
  .loblogo {
    position: relative;
    top: 25px;
  }

  & .lob-button {
    height: 39px;
    flex-grow: 1;
    padding: 8px 0 9px;
    border-bottom: solid 1px #d8d7d4;
  }
  & .lob-button.active {
    height: 39px;
    flex-grow: 1;
    padding: 8px 0 9px;
    border-bottom: solid 4px #017429;
  }
  & .heading {
    font-size: 15px;
    font-weight: 600;
  }
  & .subheading {
    font-size: 12px;
    font-weight: 500;
    color: #2d2c2a;
  }
  & .lob {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  & .session-info {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    & .session-info-section1 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
    & .session-info-section2 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }
    & .session-info-section3 {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-end;
    }
  }
  & .address {
    font-size: 12px;
    font-weight: 500;
    color: #626262;
  }

  & .previous-sessions {
    overflow-y: auto;
  }

  & .previous-session {
    width: 100%;
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    border-bottom: solid 1px #d8d7d4;
    :hover {
      background-color: #f2f1ef;
    }
    cursor: pointer;
  }
  & .quote {
    padding-left: 15px;
  }

  & .quote-history-copy {
    padding-left: 15px;
    font-size: 16px;
    font-weight: normal;
    color: #626262;
  }

  & .quote-tool-button.active {
    background-color: #f2f1ef !important;
  }

  & .icons {
    display: flex;
    justify-content: flex-start;
    margin: 7.5px 0;
    color: #157f3a;
  }
`;
const QuoteTools = () => {
  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const coverages = useSelector((store) => store.coverages);
  const drivers = useSelector((store) => store.drivers);
  const property = useSelector((store) => store.property);
  const vehicles = useSelector((store) => store.vehicles);
  const selectedDrivers = drivers.filter((driver) => driver.selected);
  const selectedVehicles = vehicles.filter((vehicle) => vehicle.selected);

  const [priorInsuranceInfo, setPriorInsuranceInfo] =
    useState<PriorInsuranceInfo>({});

  const [isFetchingQuoteHistory, setIsFetchingQuoteHistory] =
    useState<boolean>(false);

  const {
    incidents,
    prior_carrier,
    years_with_prior_carrier,
    prior_liability_limits,
  } = priorInsuranceInfo;

  const filterByType = (type: string) => {
    return incidents?.filter((incident) => incident.type === type) || [];
  };
  const numberOfAccidents = filterByType("Accident").length;
  const numberOfViolations = filterByType("Violation").length;
  const numberOfCompLosses = filterByType("Comp Loss").length;

  const [activeQuoteTool, setActiveQuoteTool] = useState<
    "summary" | "history" | ""
  >("");

  const [previousSessions, setPreviousSessions] = useState<PreviousSession[]>(
    []
  );

  const initialSelectedLob: "home" | "auto" = useMemo(
    () => (contact.homeQuoteId ? home : auto),
    [contact.homeQuoteId]
  );
  const [selectedLob, setSelectedLob] = useState(initialSelectedLob);

  useEffect(() => {
    setSelectedLob(initialSelectedLob);
  }, [initialSelectedLob]);

  const { clickOutsideRef } = useDropdown({
    onClose: () => {
      setActiveQuoteTool("");
    },
  });

  const handleClickHistoryButton = async () => {
    try {
      setIsFetchingQuoteHistory(true);
      setActiveQuoteTool(HISTORY);
      if (previousSessions.length) {
        setIsFetchingQuoteHistory(false);
        return;
      }
      let sessionsByAccountId = (
        await getSessionsBySfAccountId(contact.sfAccountId)
      ).data;
      sessionsByAccountId = sessionsByAccountId.filter(
        (s: PreviousSession) => s.uuid !== session.uuid
      );
      setPreviousSessions(sessionsByAccountId);
      setIsFetchingQuoteHistory(false);
    } catch (error) {
      setPreviousSessions([]);
      setIsFetchingQuoteHistory(false);
      console.log("Error fetching previous sessions", error);
    }
  };

  const handleClickSummaryButton = async () => {
    try {
      const autoDetails = await fetchAutoDetails();
      const incidentsFromCurrentQuote: Incident[] = [
        ...(await getAccidentsViolations(contact.autoQuoteId)).data,
      ]
        .filter(filterIncident)
        .map(mapIncidentResponseToForm)
        .filter((incident) => incident !== null) as Incident[];

      setPriorInsuranceInfo((prev) => ({
        ...prev,
        ...autoDetails,
        incidents: [...incidentsFromCurrentQuote],
      }));

      setActiveQuoteTool(SUMMARY);
    } catch (error) {
      console.log("Error fetching auto details", error);
      setActiveQuoteTool(SUMMARY);
    }
  };

  const HomeOutlinedIcon = getLobIcon(home, "outline");
  const AutoOutlinedIcon = getLobIcon(auto, "outline");

  return (
    <StyledQuoteTools ref={clickOutsideRef}>
      <div className="quote-tools">
        <button
          className={`quote-tool-button ${
            activeQuoteTool === SUMMARY && "active"
          }`}
          onClick={handleClickSummaryButton}
        >
          <Summary />
          Summary
        </button>
        <button
          className={`quote-tool-button ${
            activeQuoteTool === HISTORY && "active"
          }`}
          onClick={handleClickHistoryButton}
          disabled={isFetchingQuoteHistory}
        >
          <History />
          History
        </button>
        {!!activeQuoteTool && (
          <button
            className="double-arrow"
            onClick={() => {
              setActiveQuoteTool("");
            }}
          >
            <DoubleArrowRight />
          </button>
        )}
      </div>
      {!!activeQuoteTool && (
        <div className="quote-tools-content">
          {activeQuoteTool === SUMMARY && (
            <div className="summary-info">
              <>
                <span className="heading">Personal Info</span>
                <div>
                  <PersonSecondary className="person-logo" />
                  <div className="contact-name">
                    <span>{`${contact.firstName} ${contact.lastName}`}</span>
                  </div>
                  <div className="quote-info">
                    <div className="quote-info-section1">
                      <span className="quote-info-content">
                        {contact.phone || "Missing Mobile"}
                      </span>
                      <span className="quote-info-content">
                        {contact.email || "Missing Email"}
                      </span>
                    </div>
                    <div className="quote-info-section2">
                      <span className="quote-info-content">
                        {moment(contact.dob).format("MM.DD.YY")}
                      </span>
                      <span className="quote-info-content">
                        {contact.gender}
                      </span>
                    </div>
                  </div>
                </div>
                {contact.spouseId && (
                  <div>
                    <PersonSecondary className="person-logo" />
                    <div className="contact-name">
                      <span>
                        {`${contact.spousefirstName} ${contact.spouselastName}`}
                      </span>
                    </div>
                    <div className="quote-info">
                      <div className="quote-info-section1">
                        <span className="quote-info-content">
                          {contact.spousePhone || "Missing Mobile"}
                        </span>
                        <span className="quote-info-content">
                          {contact.spouseEmail || "Missing Email"}
                        </span>
                      </div>
                      <div className="quote-info-section2">
                        <span className="quote-info-content">
                          {moment(contact.spouseDob).format("MM.DD.YY")}
                        </span>
                        <span className="quote-info-content">
                          {contact.spouseGender}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  <span className="heading">Policy Info</span>
                </div>
                {isMultiLine() && (
                  <div className="d-flex justify-content-center mt-2">
                    {contact.homeQuoteId && (
                      <button
                        className={`lob-button ${
                          selectedLob === home && "active"
                        }`}
                        onClick={() => {
                          setSelectedLob(home);
                        }}
                      >
                        {getHomeQuotesTitle()}
                      </button>
                    )}
                    {contact.autoQuoteId && (
                      <button
                        className={`lob-button ${
                          selectedLob === auto && "active"
                        }`}
                        onClick={() => {
                          setSelectedLob(auto);
                        }}
                      >
                        Auto
                      </button>
                    )}
                  </div>
                )}
                {selectedLob === home && (
                  <div className="lob-content">
                    {!!HomeOutlinedIcon && (
                      <HomeOutlinedIcon className="loblogo" />
                    )}
                    <div className="quote-info">
                      <div className="quote-info-section1">
                        <span className="lob">{getHomeQuotesTitle()}</span>
                      </div>
                      <div className="quote-info-section2">
                        <span className="quote-info-content">
                          {`Eff: ${moment(coverages.homeEffectiveDate).format(
                            "MM.DD.YY"
                          )}`}
                        </span>
                      </div>
                    </div>
                    <div className="quote-info">
                      <div className="quote-info-section1">
                        {property.squareFoot && (
                          <span className="quote-info-content">{`${property.squareFoot.toLocaleString()} sqft`}</span>
                        )}
                        {property.numStories && (
                          <span className="quote-info-content">{`${property.numStories} Stories`}</span>
                        )}
                        {property.roofMaterial && (
                          <span className="quote-info-content">{`${property.roofMaterial} Roof`}</span>
                        )}
                        {property.roofReplaced && (
                          <span className="quote-info-content">{`Roof ${property.roofReplaced}`}</span>
                        )}
                        {property.plumbing && (
                          <span className="quote-info-content">{`Plumbing ${property.plumbing}`}</span>
                        )}
                      </div>
                      <div className="quote-info-section2">
                        {property.yearBuilt && (
                          <span className="quote-info-content">{`${property.yearBuilt} Build`}</span>
                        )}
                        {property.exteriorWalls && (
                          <span className="quote-info-content">{`${property.exteriorWalls}`}</span>
                        )}
                        {property.foundationType && (
                          <span className="quote-info-content">{`${property.foundationType}`}</span>
                        )}
                        {property.electrical && (
                          <span className="quote-info-content">{`Electrical ${property.electrical}`}</span>
                        )}
                        {property.heating && (
                          <span className="quote-info-content">{`Heating ${property.heating}`}</span>
                        )}
                      </div>
                    </div>
                    {property.newPurchase === "Yes" && (
                      <>
                        <div className="quote-info mt-2">
                          <span className="quote-info-content">
                            {`New ${getHomeQuotesTitle()} Purchase`}
                          </span>
                        </div>
                        <div className="quote-info mt-2">
                          <span className="quote-info-content">
                            Current Address:
                          </span>
                        </div>
                        <div className="quote-info">
                          <span className="quote-info-content">
                            {getAddress("contact")}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {selectedLob === auto && (
                  <div className="lob-content">
                    {!!AutoOutlinedIcon && (
                      <AutoOutlinedIcon className="loblogo" />
                    )}
                    <div className="quote-info">
                      <div className="quote-info-section1">
                        <span className="lob">Auto</span>
                      </div>
                      <div className="quote-info-section2">
                        <span className="quote-info-content">
                          {`Eff: ${moment(coverages.autoEffectiveDate).format(
                            "MM.DD.YY"
                          )}`}
                        </span>
                      </div>
                    </div>
                    <div className="quote-info">
                      <div className="quote-info-section1">
                        <span className="subheading">Drivers</span>
                      </div>
                      <div className="quote-info-section2">
                        <span className="subheading">{`(0${selectedDrivers.length})`}</span>
                      </div>
                    </div>
                    {selectedDrivers.map((driver) => {
                      return (
                        <div
                          className="quote-info mt-1"
                          key={`${driver.firstName}-${driver.lastName}`}
                        >
                          <div className="quote-info-section1">
                            <span className="quote-info-content">
                              {` ${driver.firstName} 
                              ${driver.lastName?.charAt(0)}.`}
                            </span>
                          </div>
                          <div className="quote-info-section2">
                            <span className="quote-info-content">
                              {driver.driverLicense
                                ? `DL: ${driver.driverLicense}`
                                : "Missing DL"}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                    <div className="quote-info mt-4">
                      <div className="quote-info-section1">
                        <span className="subheading">Vehicles</span>
                      </div>
                      <div className="quote-info-section2">
                        <span className="subheading">{`(0${selectedVehicles.length})`}</span>
                      </div>
                    </div>
                    {selectedVehicles.map((vehicle) => {
                      let vehicleTitle = `${vehicle.Year?.slice(2)} ${
                        vehicle.Make
                      } ${vehicle.Model}`;
                      vehicleTitle = trimStringToMaxLength(vehicleTitle, 25);
                      return (
                        <div className="quote-info mt-2" key={vehicle.Vin}>
                          <div className="quote-info-section1 vehicle">
                            <span className="quote-info-content">
                              &apos;
                              {vehicleTitle}
                            </span>
                            <span className="quote-info-content">
                              {vehicle.Vin}
                            </span>
                          </div>
                          <div className="quote-info-section2 vehicle">
                            <span className="quote-info-content">
                              {`${
                                vehicle.comprehensive === "No Coverage"
                                  ? "No Comp."
                                  : `${convertToDollars(
                                      vehicle.comprehensive
                                    )} Comp.`
                              }`}
                            </span>
                            <span className="quote-info-content">
                              {`${
                                vehicle.collision === "No Coverage"
                                  ? "No Coll."
                                  : `${convertToDollars(
                                      vehicle.collision
                                    )} Coll.`
                              }`}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                    <div className="quote-info mt-4">
                      <div className="quote-info-section1">
                        <span className="subheading">History</span>
                      </div>
                    </div>
                    <div className="quote-info mt-2">
                      <div className="quote-info-section1">
                        <span className="quote-info-content">{`${prior_carrier}, ${
                          years_with_prior_carrier || 0
                        }yrs`}</span>
                        <span className="quote-info-content">Accidents</span>
                        <span className="quote-info-content">Comp Losses</span>
                        <span className="quote-info-content">Violations</span>
                      </div>
                      <div className="quote-info-section2">
                        <span className="quote-info-content">
                          {prior_liability_limits || "No Prior Limits"}
                        </span>
                        <span className="quote-info-content">
                          {numberOfAccidents}
                        </span>
                        <span className="quote-info-content">
                          {numberOfCompLosses}
                        </span>
                        <span className="quote-info-content">
                          {numberOfViolations}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
          {activeQuoteTool === HISTORY && (
            <div className="history-info">
              <>
                <span className="heading quote">Quote History</span>
                {!isFetchingQuoteHistory && (
                  <div className="previous-sessions mt-4">
                    {previousSessions.length === 0 ? (
                      <p className="quote-history-copy">
                        There is no history for this client from the past 90
                        days.
                      </p>
                    ) : (
                      previousSessions.map((session) => {
                        const {
                          line_of_business,
                          uuid,
                          time_submitted__c,
                          property_data,
                          jewelry__c,
                        } = session;
                        const hasHome = line_of_business !== "Auto";
                        const hasAuto = line_of_business?.includes("Auto");
                        const HomeIcon = getHomeIcon(session);
                        const showJewelryIcon = Number(jewelry__c) > 0;
                        return (
                          <a
                            key={uuid}
                            className="previous-session pt-3 pb-2"
                            href={`/login?rs=${uuid}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="session-info">
                              <div className="session-info-section-1">
                                <span className="">{`${
                                  contact.firstName
                                } ${contact.lastName?.charAt(0)}.`}</span>
                              </div>
                              <div className="session-info-section-2">
                                <span>
                                  {moment(time_submitted__c).format("MM.DD.YY")}
                                </span>
                              </div>
                              <div className="session-info-section-3">
                                <span>
                                  {moment(time_submitted__c).format("hh:mm A")}
                                </span>
                              </div>
                            </div>
                            <div className="mt-1 d-flex justify-content-start">
                              <span className="address">
                                <Location />
                                {getPropertyAddressFromSessionPropertyData(
                                  property_data
                                )}
                              </span>
                            </div>
                            <div className="icons">
                              {hasHome && <HomeIcon />}
                              {hasAuto && <Auto />}
                              {showJewelryIcon && <Jewelry />}
                            </div>
                          </a>
                        );
                      })
                    )}
                  </div>
                )}
              </>
            </div>
          )}
        </div>
      )}
    </StyledQuoteTools>
  );
};

export default QuoteTools;
