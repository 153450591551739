import { Accordion } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import caretDown from "../../assets/images/components/response-page/caretDown.svg";
import styled from "styled-components";

type StyledCheckoutInfoProps = {
  $checkoutBgColor: string;
  $checkoutTextColor: string;
  $infoTextColor?: string;
  $checkoutBorder?: string;
};
export const StyledCheckoutInfo = styled.div<StyledCheckoutInfoProps>`
  position: relative;
  font-family: "Rethink Sans Variable", sans-serif;
  & .checkout {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${({ $checkoutBgColor }) => $checkoutBgColor};
    color: ${({ $checkoutTextColor }) => $checkoutTextColor};
    width: 120px;
    height: 31px;
    border-radius: 39px;
    border: ${({ $checkoutBorder }) => $checkoutBorder ?? "none"};
    font-weight: bold;
    font-size: 16px;
    position: relative;
    & svg {
      color: ${({ $checkoutTextColor }) => $checkoutTextColor};
      height: 20px;
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  & .info {
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 600;
    color: ${({ $infoTextColor }) => $infoTextColor};
    width: 100%;
    text-align: center;
  }

  & .resume {
    position: absolute;
    left: 50%;
    top: -18px;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 600;
    color: #004719;
    width: 100%;
    text-align: center;
  }
`;

type StyledQuoteCardProps = {
  $accordionBodyPaddingRight: string;
};

type StyledQuoteCardListProps = {
  $backgroundColor: string;
  $paddingX?: string;
};

export const StyledQuoteAlert = styled.div<StyledQuoteCardListProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 ${({ $paddingX }) => $paddingX ?? "0"};
  & .alert-icon {
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    border-radius: 50%;
  }
  & .alert-text {
    position: absolute;
    bottom: -15px;
    font-family: "Rethink Sans Variable", sans-serif;
    font-size: 10px;
    color: #d18400;
    font-weight: 600;
  }
`;

type StyledQuoteAlertTooltipProps = {
  $color: string;
  $backgroundColor: string;
};
export const StyledQuoteAlertTooltip = styled(
  Tooltip
)<StyledQuoteAlertTooltipProps>`
  & > .tooltip-inner {
    background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
    max-width: 300px;
    color: ${({ $color }) => $color};
    font-weight: 500;
  }
  & .tooltip-arrow::before {
    border-top-color: ${({ $backgroundColor }) => $backgroundColor}!important;
  }
`;

export const StyledQuoteCard = styled(Accordion)<StyledQuoteCardProps>`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.quote_border};
  }

  & .right-section {
    display: flex;
    gap: 9px;
    align-items: center;
  }
  /* hack to make the 2 border-left meet each other */
  /* transform up to let the border-left take the place for the button */
  & .bundle-dropdown-container,
  & .monoline-dropdown-container {
    transform: translateY(-10px);
    padding-top: 10px;
  }

  & .accordion-button {
    /* override Bootstrap styles */
    background-color: white;
    border: none;
    padding: 0;
    width: unset;
    &:focus {
      border: none;
      box-shadow: none;
    }
    &:focus-visible {
      box-shadow: 1px auto rgb(16, 16, 16);
    }
    &:not(.collapsed) {
      box-shadow: none;
    }
    &::after {
      /* arrow is a background image in Bootstrap */
      width: 45px;
      height: 45px;
      margin-left: 0;
      background-image: url(${caretDown as string});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 15px 15px;
    }
  }
  & .quote-card {
    padding: 10px 0 10px 10px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Override Bootstrap CSS */
    /* so that the border isn't hidden by the button on hover */
    z-index: 0;
    & .quote-bundle {
      display: flex;
      align-self: stretch;
      gap: 60px;
      flex: 1;
      padding: 0 32px;
      border-left: 1px solid #d8d7d4;
    }
    & .quote-card-info {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      & .quote-card-left {
        display: flex;
        gap: 10px;
      }
      & .name-in-middle {
        font-size: 12px;
        font-weight: bold;
        color: #626262;
      }
    }
    & .total-premium-info {
      position: relative;
      width: 80px;
      height: 30px;
      margin-right: 32px;
      display: flex;
      justify-content: end;
      align-items: center;
      flex-shrink: 0;
      text-align: right;
      font-family: "Rethink Sans Variable", sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: #2d2c2a;
    }
    & .below-info {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateY(100%);
      font-size: 10px;
      font-weight: 500;
      color: #626262;
      text-align: center;
    }
    & .accordion-button-filler {
      width: 45px;
    }
  }
  & .accordion-body {
    display: flex;
    gap: 60px;
    padding: 0 0 10px 0;
    & .monoline-dropdown-container {
      padding-left: 10px;
      padding-right: 45px;
      width: 100%;
    }
    & .bundle-dropdown-container {
      display: flex;
      width: 100%;
      gap: 60px;
      border-left: 1px solid #d8d7d4;
      padding-left: 32px;
      padding-right: calc(32px + 45px);
      margin-left: 122px;
      justify-content: end;
    }
  }
  .smart-home-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: "Rethink Sans Variable", sans-serif;
    & .heading {
      font-size: 13px;
      font-weight: bold;
    }
    & .security-quote-details-right {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px 16px;
      & span {
        flex-grow: 0;
        font-size: 12px;
        text-align: left;
        color: #2d2c2a;
        font-weight: 500;
      }
    }
  }
  & .quote-details {
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
    gap: 22px;
    font-family: "Rethink Sans Variable", sans-serif;
    font-size: 14px;
    color: ${({ theme }) => theme.quote_info_text};

    & .quotes-with-errors {
      & .error-message {
        margin-bottom: 14px;
      }
      display: flex;
      flex-direction: column;
      gap: 13px;

      & .dropdown-first-name {
        font-weight: 600;
        font-size: 16px;
      }
    }

    .quote-details-main {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      & .carrier-guidelines {
        color: #017429;
        flex: 1;
        font-weight: 500;
      }
      & .quote-details-left {
        display: flex;
        flex-direction: column;
        gap: 13px;
        flex: 0 0 120px;
        & .dropdown-first-name {
          font-weight: 600;
          font-size: 16px;
        }
      }
      & .quote-details-right {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;
        text-align: right;
        & div {
          display: flex;
          justify-content: space-between;
          gap: 0.5em;
        }
      }
      .error-message {
        text-align: right;
      }
    }
    & .company_quote_number {
      font-size: 14px;
      color: ${({ theme }) => theme.quote_footer_text};
    }
    & .quote-footer {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
    }
  }
`;

export const StyledQuoteSpouseButton = styled.button`
  padding: 6px 2.5px 5px 6.5px;
  border-radius: 7px;
  border: solid 1px #d8d7d4;
  color: #626262;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  line-height: 1;
  &:hover {
    transform: translateY(-1px);
  }
`;

type StyledIconProps = {
  $color?: string;
  $backgroundColor?: string;
};

export const StyledIcon = styled.div<StyledIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  font-family: "Rethink Sans Variable", sans-serif;
`;

export const StyledQuoteCardList = styled.div`
  overflow-y: auto;
  scrollbar-gutter: stable;

  & h3 {
    font-family: "Rethink Sans Variable", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #4c4c4c;
    margin: 0;
    padding: 8px 0 8px 24px;
    border: solid 1px ${({ theme }) => theme.quote_border};
    background-color: #fafafa;
  }
  & .quotes {
    padding-left: 15px;
    & .package-required-prompt {
      width: 100%;
      font-family: "Rethink Sans Variable", sans-serif;
      padding: 22px 0;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: black;
    }
  }
`;

export const CarrierLogoNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  color: ${({ theme }) => theme.carrier_name_text};
  font-size: 15px;
  font-weight: 600;
  font-family: "Rethink Sans Variable", sans-serif;
  line-height: 1.5;
  letter-spacing: -0.17px;

  & .initial {
    background-color: ${({ theme }) => theme.placeholder_bg};
    color: ${({ theme }) => theme.placeholder_text};
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
  }

  & .logo {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    object-fit: cover;
  }
`;

export const StyledBundleHeaderContainer = styled.div`
  display: flex;
  font-family: "Rethink Sans Variable", sans-serif;
  height: 37px;
  padding: 7px 23px;
  background-color: #f2f1ef;
  border-radius: 8px 8px 0 0;

  & .packages {
    flex: 0 0;
    flex-basis: 155px;
    display: flex;
    align-items: center;
    gap: 10px;
    & svg {
      width: 25px;
      height: 25px;
    }
    & span {
      font-size: 15px;
      font-weight: 600;
      color: #2d2c2a;
    }
  }
  & .lobs {
    flex: 1;
    display: flex;
    gap: 25px;
    & .lob-icon-text {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 19px;
      color: #626262;
      & svg {
        width: 17px;
        height: 17px;
      }
      & h2 {
        margin: 0;
        display: inline;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  .num-response {
    font-size: 15px;
    font-weight: 500;
    color: #2d2c2a;
  }
`;

export const StyledQuoteLobContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px #d5d5d5;
  border-radius: 8px;
  background-color: #fff;
  max-height: 100%;
  & .header {
    display: flex;
    border-radius: 8px 8px 0 0;
    align-items: center;
    justify-content: space-between;
    height: 37px;
    padding: 7px 23px 7px 23px;
    background-color: #f2f1ef;
    font-size: 15px;
    font-weight: 600;
    & .lobs {
      display: flex;
      flex: 1;
      gap: 72px;
      padding-right: 119px;
    }
    & .lob-icon-text {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 20px;
      & svg {
        color: #157f3a;
        width: 25px;
        height: 25px;
      }
      & h2 {
        font-family: "Rethink Sans Variable", sans-serif;
        color: #2d2c2a;
        margin: 0;
        display: inline;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  & .quote-list-content {
    overflow-y: auto;
    /* TODO: add gutter but do not double it with parent's gutter */
  }

  & .addLobButton {
    width: 120px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 10px;
    border-radius: 34px;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border: solid 1px #bbf368;
    background-color: #ceff85;
    color: #004c18;
  }

  & .addLobText {
    width: 203px;
    height: 60px;
    flex-grow: 0;
    font-family: "Rethink Sans Variable", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.1;
    text-align: left;
    color: #4c4c4c;
  }

  & .addLobSubText {
    width: 203px;
    height: 28px;
    flex-grow: 0;
    margin: 60px 0 0;
    font-family: "Rethink Sans Variable", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #636363;
  }
`;

type StyledRatesContainerProps = {
  isDwellingOnly: boolean;
};
export const StyledRatesContainer = styled.main<StyledRatesContainerProps>`
  height: 100%;
  background-color: #f8f8f8;
  padding: 0 12.5px;

  & .width-container {
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    gap: 12.5px;
    justify-content: flex-start;

    & > .monoline-section {
      width: 35%;
    }
    & > .bundle-section {
      width: 70%;
    }
    & > .additional-section {
      display: flex;
      flex-direction: column;
      gap: 12.5px;
      width: 30%;
      overflow-y: auto;
      scrollbar-gutter: stable;
      &::-webkit-scrollbar-thumb {
        border-color: #f8f8f8;
      }

      /* quotes on the additional section should grow indefinitely */
      /* as this section is already scrollable */
      & .quote-list-container {
        max-height: none;
      }
    }
  }
`;

export const StyledLobSelectionButtonContainers = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: center;
  margin: 3rem 0;
`;

type StyledLobSelectionButtonProps = {
  $color: string;
  $iconColor: string;
  $backgroundColor: string;
};
export const StyledLobSelectionButton = styled.button<StyledLobSelectionButtonProps>`
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border-radius: 10px;
  font-weight: bold;
  font-family: "Rethink Sans Variable", sans-serif;
  font-size: 1.5rem;
  padding: 0.5rem 2rem;
  & svg {
    color: ${({ $iconColor }) => $iconColor};
    width: 160px;
    height: 160px;
  }
`;

export const StyledSkipButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  background: none;
  margin: 0 auto;
  color: #2d424d;
  font-size: 13px;
  text-decoration: underline;

  & svg {
    width: 25px;
    height: 25px;
    color: #6cb545;
  }
`;
