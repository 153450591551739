import "../../assets/css/Selection.css";

import { Button, CloseButton } from "react-bootstrap";
import { Dispatch, SetStateAction, useState } from "react";
import {
  updateContactInPg,
  updateSfContactByAccountId,
} from "../../services/account";
import { useDispatch, useSelector } from "../../redux/hooks";

import { ReactComponent as CaretRight } from "../../assets/images/components/response-page/caretRight.svg";
import CustomInput from "../styled/inputs/CustomInput";
import CustomSelect from "../styled/inputs/CustomSelect";
import { CustomText } from "../styled/typography";
import { ReactComponent as DollarSign } from "../../assets/images/components/dollar_sign.svg";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { VIVINT_SMART_HOME_QUOTE } from "../../constants";
import { addContact } from "../../redux/actions/contact";
import { handleUpdateSession } from "../../services/utils";
import styled from "styled-components";
import { useFormik } from "formik";
import { vivintContactDataValidationSchema } from "../../utils/validation";

type VivintHomePackageSelectionModalProps = {
  showNewSmartHomePackageSelectionModal: boolean;
  setShowNewSmartHomePackageSelectionModal: Dispatch<SetStateAction<boolean>>;
  setShowNewSmartHomeConsentModal: Dispatch<SetStateAction<boolean>>;
};

type ContactData = {
  contactId: string | undefined;
  phone: string | undefined;
  email: string | undefined;
};

type UpdatedContact = Partial<
  ContactData & {
    packageSelected: string;
    referredContactId: string;
    spousePhone: string;
    spouseEmail: string;
  }
>;

const SmartHomeContentWrapper = styled.div`
  font-family: Rethink Sans Variable;
  & .form-container {
    width: 780px;
    height: 680px;
    padding: 34px 34px 78px 34px;
    border-radius: 25px;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
  & label {
    font-size: 14px;
    font-weight: 500;
    color: #2d2c2a;
  }
  & .dollar-sign {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 10px;
    bottom: 4px;
    background-color: #d3f59e;
    border-radius: 50%;
  }
  & .header-text {
    margin-bottom: 40px;
  }
  & .price-box {
    border: solid 2px #157f3a;
    border-radius: 15px;
    padding: 20px;
    background-color: white;
    padding: 20px 16px;
    & .title-box {
      text-align: center;
    }
    & .title-text1 {
      font-size: 24px;
      font-weight: bold;
      color: #2d2c2a;
    }
    & .title-text2 {
      font-size: 14px;
      color: #2d2c2a;
      margin-left: 5px;
    }
    & ul {
      list-style-type: none;
      padding: 0;
      margin: 18px 18px 0 50px;
    }
    & p {
      margin: 12px 18px 15px 50px;
      font-size: 12px;
      font-weight: 500;
      color: #2d2c2a;
    }
    & ul li {
      text-align: left;
      margin-bottom: 10px;
      font-size: 14px;
      color: #2d2c2a;
    }
  }
  & .row-equal-height {
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    & > [class*="col-"] {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  & .small {
    text-align: center;
    margin-top: 40px;
    font-size: 12px;
    font-weight: 500;
    color: #74757b;
  }
  & .closeButton {
    float: right;
    position: absolute;
    right: -58px;
    top: 24px;
  }
  & .submitButton {
    width: 232px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    margin: 40px 157px 0;
    padding: 3px 15px;
    border-radius: 39px;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border: solid 1px #157f3a;
    background-color: #157f3a;
    color: #edfdd6;
  }
`;

const VivintHomePackageSelectionModal = ({
  showNewSmartHomePackageSelectionModal,
  setShowNewSmartHomePackageSelectionModal,
  setShowNewSmartHomeConsentModal,
}: VivintHomePackageSelectionModalProps) => {
  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    contactId: contact.contactId,
    phone: contact.phone,
    email: contact.email,
  };

  const handleSubmit = async (contactData: ContactData) => {
    try {
      setIsSubmitting(true);
      await updateContactInPg({
        account_id: contact.accountId,
        contactId: contactData.contactId,
        mobilephone: contactData.phone,
        email: contactData.email,
      });
      await updateSfContactByAccountId({
        accountId: contact.accountId,
        fieldsToSync: ["mobilephone", "email"],
      });
      let updatedContact: UpdatedContact = {
        packageSelected: "Vivint Package 1",
        referredContactId: contactData.contactId,
      };
      if (contactData.contactId === contact.contactId) {
        updatedContact = {
          ...updatedContact,
          phone: contactData.phone,
          email: contactData.email,
        };
      } else if (contactData.contactId === contact.spouseId) {
        updatedContact = {
          ...updatedContact,
          spousePhone: contactData.phone,
          spouseEmail: contactData.email,
        };
      }
      await handleUpdateSession({
        uuid: session.uuid,
        contact_data: JSON.stringify({
          ...contact,
          ...updatedContact,
        }),
      });
      dispatch(addContact({ ...contact, ...updatedContact }));
      setShowNewSmartHomePackageSelectionModal(false);
      setIsSubmitting(false);
      setShowNewSmartHomeConsentModal(true);
    } catch (error) {
      console.log("Error Selecting Smart Home Package", error);
      setIsSubmitting(false);
    }
  };

  const {
    values: contactData,
    handleChange,
    errors,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: vivintContactDataValidationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Modal
      show={showNewSmartHomePackageSelectionModal}
      centered
      className="vivintSmartSelection"
    >
      <SmartHomeContentWrapper>
        <form className="form-container">
          <div>
            <div className="header-text text-center">
              <CustomText
                color="#2d2c2a"
                fontSize="20px"
                fontWeight="bold"
                fontFamily=" Rethink Sans Variable"
                decoration={null}
                tabIndex={null}
                handleClick={null}
              >
                Refer To Vivint Smart Home
              </CustomText>
              <span className="dollar-sign">
                <DollarSign />
              </span>
            </div>
            <CloseButton
              // @ts-ignore
              variant="black"
              onClick={() => setShowNewSmartHomePackageSelectionModal(false)}
              className="closeButton"
              id="package-selection-close-button"
            />
            <div>
              <div className="row row-equal-height">
                <div className="col-md-6">
                  <div className="price-box">
                    <div className="title-box">
                      <span className="title-text1">
                        ${VIVINT_SMART_HOME_QUOTE.premium_total_ppm__c}
                      </span>
                      <span className="title-text2">per month*</span>
                    </div>

                    <p>Includes installation and monitoring of:</p>
                    <ul>
                      <li>1 Vivint Smart Hub</li>
                      <li>1 Smart Thermostat</li>
                      <li>1 Vivint Outdoor Camera Pro</li>
                      <li>1 Vivint Doorbell Camera Pro (Free Item GHSD)</li>
                      <li>1 Smart Lock</li>
                      <li>3 Vivint Door/Window Sensors</li>
                      <li>1 Vivint Water Sensor</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <div className="d-flex flex-column flex-grow-1 justify-content-between">
                    {contact.spouseId ? (
                      <div>
                        <CustomSelect
                          className="form-control 89"
                          id="contactId"
                          type="text"
                          name="contactId"
                          value={contactData.contactId}
                          handleChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const newValue = e.target.value;
                            const newFormValues: ContactData =
                              newValue === contact.contactId
                                ? {
                                    contactId: contact.contactId!,
                                    phone: contact.phone ?? "",
                                    email: contact.email ?? "",
                                  }
                                : {
                                    contactId: contact.spouseId!,
                                    phone: contact.spousePhone ?? "",
                                    email: contact.spouseEmail ?? "",
                                  };
                            formik.setValues(newFormValues);
                          }}
                          label="Point of Contact"
                          aria-label="Point of Contact"
                        >
                          <option value={contact.contactId}>
                            {`${contact.firstName} ${contact.lastName}`}
                          </option>
                          <option value={contact.spouseId}>
                            {`${contact.spousefirstName} ${contact.spouselastName}`}
                          </option>
                        </CustomSelect>
                      </div>
                    ) : (
                      <CustomInput
                        // @ts-ignore
                        label="Point of Contact"
                        className="form-control"
                        type="text"
                        name="contactId"
                        id="contactId"
                        aria-label="Point of Contact"
                        value={`${contact.firstName} ${contact.lastName}`}
                        readOnly
                      />
                    )}
                    <div>
                      <CustomInput
                        // @ts-ignore
                        label="Mobile Number"
                        className="form-control"
                        type="phone"
                        name="phone"
                        id="phone"
                        aria-label="Mobile Number"
                        placeholder="(555) 123-4567"
                        value={contactData.phone ?? ""}
                        handleChange={handleChange}
                        error={errors.phone}
                        masked
                        mask="(999) 999-9999"
                        maskPlaceholder=""
                        inputMode="numeric"
                      />
                    </div>
                    <div>
                      <CustomInput
                        // @ts-ignore
                        label="Email Address"
                        className="form-control"
                        type="text"
                        name="email"
                        id="email"
                        placeholder="username@email.com"
                        aria-label="Email Address"
                        value={contactData.email ?? ""}
                        handleChange={handleChange}
                        error={errors.email}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="small">
            *The rate is just an estimate. Final monthly price will be based on
            credit, down payment, and final equipment list.
          </div>
          <div>
            <div className="row justify-content-center mb-5">
              <Button
                // @ts-ignore
                onClick={formik.handleSubmit}
                disabled={isSubmitting}
                className="submitButton"
                type="submit"
              >
                Continue
                <CaretRight />
              </Button>
            </div>
          </div>
        </form>
      </SmartHomeContentWrapper>
    </Modal>
  );
};
export default VivintHomePackageSelectionModal;
