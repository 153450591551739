import { addPackage, addRent } from "../redux/actions/quote";
import {
  getPageName,
  getPagenumber,
  getPropertyAddress,
} from "../utils/strings";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AboutYou from "./AboutYou";
import AddressInfo from "./AddressInfo";
import AutoDetails from "./AutoDetails";
import { CustomText } from "../components/styled/typography";
import Drivers from "./Drivers";
import LinkButton from "../components/styled/buttons/LinkButton";
import { MainWrapper } from "../components/styled/wrappers";
import PolicyType from "./PolicyType";
import { ProgressBar } from "../components/new_design/ProgressBar";
import PropertyInfo from "./PropertyInfo";
import Quotes from "./Quotes";
import { Seperator } from "../components/styled/seperator";
import Spinner from "../components/styled/Spinner";
import Vehicles from "./Vehicles";
import { addContact } from "../redux/actions/contact";
import { addDrivers } from "../redux/actions/driver";
import { addVehicles } from "../redux/actions/vehicle";
import { getAccountDetailsinSf } from "../services/account";
import { getPageNameFromUrl } from "../utils";
import { handleUpdateSession } from "../services/utils";
import { pageIndices } from "../constants";
import { updateIsFetching } from "../redux/actions/fetching";
import { updateSessionFromRedux } from "../redux/utils";
import { useIsNewResponsePageDesign } from "../utilsMisc";

const LineOfBusiness = () => {
  const params = useParams();
  const lob = params.line_of_business;

  const currentPage = getPagenumber(params.line_of_business, params.page);
  const navigate = useNavigate();

  const session = useSelector((store) => store.session);
  const token = useSelector((store) => store.token);
  const property = useSelector((store) => store.property);
  const contact = useSelector((store) => store.contact);
  const coverages = useSelector((store) => store.coverages);
  const drivers = useSelector((store) => store.drivers);
  const vehicles = useSelector((store) => store.vehicles);
  const isFetching = useSelector((store) => store.isFetching);

  const [indices, setIndices] = useState([]);
  const [showAddressInfo, setShowAddressInfo] = useState(false);
  const [showManualAddress, setShowManualAddress] = useState(false);

  const shouldUseNewResponseDesign = useIsNewResponsePageDesign();

  const dispatch = useDispatch();

  const isResponsesPage = getPageNameFromUrl() === "Responses";

  const handleNext = async () => {
    try {
      let newPage = currentPage + 1;
      const sessionData = await handleUpdateSession({
        uuid: session.uuid,
        line_of_business: lob,
        page_stopped: newPage,
      });
      navigate(
        `/agent/${sessionData.line_of_business}/${getPageName(
          sessionData.line_of_business,
          sessionData.page_stopped
        )}`
      );
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const handleBack = async () => {
    try {
      let newPage = currentPage - 1;
      if (currentPage === 1) {
        navigate("/");
      } else {
        let line_of_business = lob;
        if (lob === "Auto" && currentPage === 2) {
          line_of_business = "HomeAuto";
        }
        const sessionData = await handleUpdateSession({
          uuid: session.uuid,
          page_stopped: newPage,
          line_of_business: line_of_business,
        });
        navigate(
          `/agent/${sessionData.line_of_business}/${getPageName(
            sessionData.line_of_business,
            newPage
          )}`
        );
        setIndices(pageIndices[sessionData.line_of_business]);
      }
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const clearQuoteDetails = async () => {
    let updatedContactData = {
      ...contact,
      accountId: undefined,
      autoPropertyId: undefined,
      autoQuoteId: undefined,
      contactId: undefined,
      homeQuoteId: undefined,
      propertyId: undefined,
    };
    /* sending an empty array for drivers and vehicles
       to clear drivers so lexisnexis will be called again
       when we dont do this it pulls the old contact on Drivers page with no contactID
       this is causing Drivers page to duplicate contact */
    await handleUpdateSession({
      uuid: session.uuid,
      contact_data: JSON.stringify(updatedContactData),
      drivers_data: JSON.stringify([]),
      vehicles_data: JSON.stringify([]),
      auto_quote_id: undefined,
      home_quote_id: undefined,
    });
    dispatch(addContact(updatedContactData));
    dispatch(addDrivers([]));
    dispatch(addVehicles([]));
  };

  useEffect(() => {
    if (lob && lob.includes("Renter")) {
      dispatch(addRent(true));
    }
    if (lob && lob.indexOf("Auto") > 0) {
      dispatch(addPackage(true));
    }
    if (token) {
      setShowManualAddress(false);
      updateSessionFromRedux();
    }
    setIndices(pageIndices[lob]);
    // eslint-disable-next-line
  }, [
    contact,
    property,
    drivers,
    vehicles,
    coverages,
    currentPage,
    indices,
    lob,
  ]);

  useEffect(() => {
    if (Object.keys(session).length && !session.agent_sfid) {
      navigate(`/login`);
    }
    // eslint-disable-next-line
  }, [session.agent_sfid]);

  useEffect(() => {
    if (contact.sfAccountId && !contact.updatedNotesFromSf) {
      const getAccountNotes = async () => {
        let sfAccountDetails = await getAccountDetailsinSf(contact.sfAccountId);

        sfAccountDetails = sfAccountDetails.data;

        if (sfAccountDetails.quote_discovery_notes__c) {
          let agentNotes = sfAccountDetails.quote_discovery_notes__c;
          if (
            contact.agentNotes &&
            sfAccountDetails.quote_discovery_notes__c !== contact.agentNotes
          ) {
            agentNotes =
              sfAccountDetails.quote_discovery_notes__c +
              "\n" +
              contact.agentNotes;
          }

          dispatch(
            addContact({
              agentNotes: agentNotes,
              updatedNotesFromSf: true,
            })
          );
        }
      };
      getAccountNotes();
    }
    // eslint-disable-next-line
  }, [contact.sfAccountId]);

  const propertyAddress = getPropertyAddress();

  const isNewResponse = shouldUseNewResponseDesign && isResponsesPage;

  return showAddressInfo ? (
    <AddressInfo
      isAddressUpdate
      closeUpdate={() => {
        setShowAddressInfo(false);
        dispatch(updateIsFetching(false));
      }}
      clearQuoteDetails={clearQuoteDetails}
    />
  ) : (
    <>
      {indices && indices.length > 0 && (
        <MainWrapper isNewResponse={isNewResponse}>
          {!showManualAddress && (
            <>
              {!contact.isBlocked && !isNewResponse && (
                <ProgressBar handleBack={handleBack} lob={lob} />
              )}
              {(session.page_stopped === 1 || currentPage <= indices[1]) &&
                !isFetching && (
                  <>
                    <div className="text-center">
                      {propertyAddress ? (
                        <a
                          target="_blank"
                          href={`https://www.google.com/search?q=${property.street_number}, ${property.locality}, ${property.administrative_area_level_1} ${property.postal_code}`}
                          rel="noreferrer"
                        >
                          <CustomText
                            color="secondary"
                            fontSize="medium"
                            decoration="underline"
                          >
                            {propertyAddress}
                          </CustomText>
                        </a>
                      ) : (
                        <>
                          <Spinner />
                          <p>Getting Property Details...</p>
                        </>
                      )}
                    </div>
                    {currentPage <= indices[1] && (
                      <>
                        {propertyAddress && (
                          <div className="text-center mb-2">
                            <LinkButton
                              text="Not the correct address?"
                              handleClick={() => {
                                setShowAddressInfo(true);
                              }}
                            ></LinkButton>
                          </div>
                        )}
                        <div className="d-flex justify-content-center">
                          <Seperator />
                        </div>
                      </>
                    )}
                  </>
                )}

              {currentPage === indices[0] && (
                <PolicyType handleNext={handleNext} />
              )}

              {currentPage === indices[1] && (
                <PropertyInfo handleNext={handleNext} />
              )}

              {currentPage === indices[2] && (
                <AboutYou handleNext={handleNext} />
              )}

              {currentPage === indices[3] && (
                <Drivers handleNext={handleNext} setIndices={setIndices} />
              )}

              {currentPage === indices[4] && (
                <Vehicles handleNext={handleNext} />
              )}

              {currentPage === indices[5] && (
                <AutoDetails handleNext={handleNext} />
              )}
              {currentPage === indices[6] && <Quotes handleNext={handleNext} />}
            </>
          )}
        </MainWrapper>
      )}
    </>
  );
};

export default LineOfBusiness;
