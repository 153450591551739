import { Route, Routes, useNavigate } from "react-router-dom";
import {
  addClientDataToLogRocket,
  initializeLogRocket,
} from "./services/logRocket";
import {
  checkForNullOrUndefinedSessionData,
  mapSessionDataToStore,
} from "./utils";
import {
  createSession,
  deleteSession,
  getLocalSessionId,
  getSession,
} from "./services/session";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddressInfo from "./pages/AddressInfo";
import CustomButton from "./components/styled/buttons/CustomButton";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LineOfBusiness from "./pages/LineOfBusiness";
import LoadingDots from "./components/LoadingDots";
import Login from "./pages/Login";
import { ResponseWrapper } from "./components/styled/wrappers";
import { addContact } from "./redux/actions/contact";
import { addCoverages } from "./redux/actions/coverages";
import { addDrivers } from "./redux/actions/driver";
import { addProperty } from "./redux/actions/property";
import { addSession } from "./redux/actions/session";
import { addToken } from "./redux/actions/token";
import { addVehicles } from "./redux/actions/vehicle";
import { getAgentDetails } from "./services/agent";
import { getPagenumber } from "./utils/strings";
import { getQueryParams } from "./utils/queryParams";
import { getQuoteRequest } from "./services/quote";
import { handleAgentDetails } from "./services/utils";
import { resetQuotesStatus } from "./utils/resubmit";
import { sendUserIdToGoogleTagManager } from "./services/gtm";
import styled from "styled-components";
import { updateAccountinPg } from "./services/account";
import { updateIsFetching } from "./redux/actions/fetching";

const Paths = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contact = useSelector((store) => store.contact);
  const session = useSelector((store) => store.session);
  const { logged_in_user } = session;
  const queryParams = getQueryParams(window.location.search);
  const [hasSessionBeenHandled, setHasSessionBeenHandled] = useState(false);
  const [showUnknownSession, setShowUnknownSession] = useState(false);

  /**
   * @param {string} agent_sfid - SF Id of the agent
   * @returns None
   *
   *   - This function fetches session from backend and fills in the redux store.
   *   - If nothing is returned from the backend then calls create session function.
   *   - This function is called on every refresh.
   */

  const handleSession = async (agent_sfid, sfAccountId, createNewAccount) => {
    try {
      dispatch(updateIsFetching(true));

      const raterSession = queryParams.rs ?? getLocalSessionId();

      const sessionResponse = await getSession(raterSession);
      const sessionData = sessionResponse.data.sessionData;
      const token = sessionResponse.data._csrf;

      dispatch(addToken(token));

      if (queryParams.rs && sessionResponse?.data?.unknownSessionId) {
        await createNewSession(agent_sfid, sfAccountId, createNewAccount);
        setShowUnknownSession(true);
        setHasSessionBeenHandled(true);
        dispatch(updateIsFetching(false));
        return;
      }

      if (sessionData && !sessionData[0].agent_rater_session) {
        const sessionCopy = { ...sessionData[0] };
        dispatch(addToken(token));
        await deleteSession(sessionCopy.uuid);
        sessionCopy.willpurchase = sessionCopy.willpurchase ? "Yes" : "No";
        delete sessionCopy.uuid;
        const {
          data: { _csrf: newToken },
        } = await getSession();

        dispatch(addToken(newToken));
        await handleNewSessionFromClientRater(sessionCopy);

        await addQuoteRequestSource({ dispatch, session: sessionCopy }).catch(
          (error) => {
            console.warn({
              fn: "Paths.handleSession",
              msg: "ClientRater session: failed to add quote source",
              error,
            });
          }
        );
      } else if (
        sessionData &&
        sessionData.length > 0 &&
        !queryParams.sfAccountId
      ) {
        let contactData;

        if (sessionData[0].coverages_data) {
          const coveragesData = JSON.parse(sessionData[0].coverages_data);
          dispatch(addCoverages(coveragesData));
        }
        if (sessionData[0].contact_data) {
          contactData = JSON.parse(sessionData[0].contact_data);
          dispatch(addContact(contactData));
        }
        if (sessionData[0].property_data) {
          const propertyData = JSON.parse(sessionData[0].property_data);
          dispatch(addProperty(propertyData));
        }
        if (sessionData[0].drivers_data) {
          const driversData = JSON.parse(sessionData[0].drivers_data);
          dispatch(addDrivers(driversData));
        }
        if (sessionData[0].vehicles_data) {
          const vehiclesData = JSON.parse(sessionData[0].vehicles_data);
          dispatch(addVehicles(vehiclesData));
        }
        if (sessionData[0].agent_sfid) {
          await handleAgentDetails(
            sessionData[0].agent_sfid,
            sessionData[0].uuid
          );
        }
        if (sessionData[0].leadSource) {
          dispatch(
            addContact({ ...contact, leadSource: sessionData[0].leadSource })
          );
        }

        dispatch(addSession(sessionData[0]));

        await addQuoteRequestSource({
          dispatch,
          session: sessionData[0],
        }).catch((error) => {
          console.warn({
            fn: "Paths.handleSession",
            msg: "AgentRater session: failed to add quote source",
            error,
          });
        });
      } else {
        await createNewSession(agent_sfid, sfAccountId, createNewAccount);
      }
    } catch (error) {
      console.log("Error on fetching session", error);
    }
    setHasSessionBeenHandled(true);
    dispatch(updateIsFetching(false));
  };

  /**
   * @param {any} payload - Payload object
   * @returns {Promise<void>} - Promise.
   *
   *   - This function creates a new session from the existing session data in
   *       client rater. -If there is a response which contains sessionData it
   *       will dispatch addSession.
   */
  const handleNewSessionFromClientRater = async (payload) => {
    payload = {
      ...payload,
      agent_rater_session: true,
      synced: false,
      page_stopped: getPagenumber(payload.line_of_business, "Responses"),
    };
    if (!payload?.line_of_business?.includes("Auto")) {
      payload = {
        ...payload,
        auto_quote_id: null,
      };
    }
    let filteredSessionData = Object.fromEntries(
      Object.entries(payload).filter(([_, v]) => v != null)
    );
    const response = await createSession(filteredSessionData);

    let sessionData = response.data.sessionData?.[0];
    if (!sessionData) {
      return;
    }

    checkForNullOrUndefinedSessionData(sessionData);
    mapSessionDataToStore(sessionData);

    dispatch(addSession(sessionData));
    if (sessionData.agent_sfid) {
      await handleAgentDetails(sessionData.agent_sfid);
    }
    const contactInfo = sessionData?.contact_data;
    await updateAccountinPg(contactInfo.accountId, {
      session_id__c: sessionData.uuid,
    });
    if (contactInfo.spouseId) {
      await resetQuotesStatus();
    }
  };

  /**
   * @param {string} agent_sfid - SF Id of the agent
   * @returns None
   *
   *   - This function creates a new session in the database and adds returned
   *       session in redux.
   *   - If agent Id is provided as param, it adds agent details to the redux.
   */
  const createNewSession = async (
    agent_sfid,
    sfAccountId,
    createNewAccount
  ) => {
    try {
      let reqBody = { agent_sfid: agent_sfid };

      let contact_data = {};
      reqBody = { ...reqBody, agent_rater_session: true };

      if (sfAccountId) {
        contact_data = {
          ...contact_data,
          sfAccountId: sfAccountId,
          accountIdFromUrl: true,
          createNewAccount: createNewAccount,
          accountQuotedFromSf: true,
        };
      }
      if (queryParams.flow === "newResponses") {
        contact_data = {
          ...contact_data,
          flow: "newResponses",
        };
      }

      if (queryParams.dummyQuotes) {
        contact_data = {
          ...contact_data,
          dummyQuotes: true,
        };
      }

      reqBody = {
        ...reqBody,
        contact_data: JSON.stringify(contact_data),
      };

      const response = await createSession(reqBody);
      const sessionData = response.data.sessionData;
      checkForNullOrUndefinedSessionData(sessionData[0]);
      if (sessionData && sessionData.length > 0) {
        dispatch(addSession(sessionData[0]));
        if (sessionData[0].agent_sfid) {
          await handleAgentDetails(sessionData[0].agent_sfid);
        }
      }
    } catch (error) {
      console.log("Error creating new session", error);
    }
  };

  const initializeLogRocketCallback = useCallback(
    async (loggedInUserId, contact) => {
      try {
        const agentResponse = await getAgentDetails(loggedInUserId);
        const agent = agentResponse.data?.agentData[0] ?? null;
        if (agent) {
          const logRocketUserDetails = {
            userId: loggedInUserId,
            name: agent.name,
            email: agent.email,
          };
          initializeLogRocket(logRocketUserDetails);
        }
        if (contact) {
          addClientDataToLogRocket(
            `${contact.firstName} ${contact.lastName}`,
            contact.email
          );
        }
      } catch (error) {
        console.log("Error initializing LogRocket", error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  useEffect(() => {
    if (logged_in_user) {
      sendUserIdToGoogleTagManager(logged_in_user);
      initializeLogRocketCallback(logged_in_user, contact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged_in_user, contact.email]);

  useEffect(() => {
    handleSession();
    // eslint-disable-next-line
  }, []);

  return (
    <ResponseWrapper>
      <Header />
      {!hasSessionBeenHandled ? (
        <LoadingDots />
      ) : showUnknownSession ? (
        <UnknownSession
          onClickNewQuote={() => {
            navigate("/address");
            setShowUnknownSession(false);
          }}
        />
      ) : (
        <Routes>
          <Route
            exact
            path="/"
            element={<Login handleSession={handleSession} />}
          />
          <Route
            exact
            path="/login"
            element={<Login handleSession={handleSession} />}
          />
          <Route
            exact
            path="/address"
            element={<AddressInfo handleSession={handleSession} />}
          />
          <Route
            exact
            path="/agent/:line_of_business/:page"
            element={<LineOfBusiness handleSession={handleSession} />}
          />
        </Routes>
      )}
      <Footer />
    </ResponseWrapper>
  );
};

Paths.propTypes = {};

export default Paths;

const UnknownSessionContainer = styled.div`
  height: 90vh;
  text-align: center;
  padding-top: 25%;
`;
function UnknownSession({ onClickNewQuote }) {
  return (
    <UnknownSessionContainer>
      <h1>Unknown Session Id Requested</h1>
      <p>
        Please double check if provided Id is valid. If it is, the session might
        have been archived.
      </p>
      <CustomButton title="New Quote" handleClick={onClickNewQuote} />
    </UnknownSessionContainer>
  );
}

async function addQuoteRequestSource({ dispatch, session }) {
  const quoteSource = await getQuoteSource(session);

  dispatch(addSession({ ...session, quoteSource }));
}

async function getQuoteSource({
  autoQuoteId,
  auto_quote_id,
  homeQuoteId,
  home_quote_id,
}) {
  const quoteId = autoQuoteId || auto_quote_id || homeQuoteId || home_quote_id;

  if (!quoteId) {
    throw new Error("No quote id found in session data");
  }

  const request = await getQuoteRequest(quoteId);

  return request?.data?.[0]?.quote_source__c;
}
